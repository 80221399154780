/*
 * @Descripttion: axios网络请求封装
 */

import axios from "axios";
import qs from "qs";

const API_ROOT = "/api";

export default {
  install(Vue) {
    let toast;

    Vue.prototype._injectToToast = function (func) {
      toast = func;
    };

    /**
     * @typedef Result
     * @property {Object} data 返回数据中的data对象
     * @property {Object} errorCode 调用失败时返回数据中的code属性
     * @property {Object} errorRaw 调用失败时返回的原始数据
     */

    /**
     * 以 await/async 方式发起POST请求
     * @param url {string}
     * @param data {Object}
     * @param autoToast {boolean} 是否在错误时自动toast
     * @return {Result}
     */
    Vue.prototype.postAsync = async function (url, data, autoToast = true) {
      const token = sessionStorage[process.env.VUE_APP_TOKEN];
      try {
        const result = await axios.post(API_ROOT + url, data, {
          headers: {
            Authorization: token,
          },
        });

        if (result.data.code !== 200) {
          if (autoToast) {
            toast.error(result.data.msg);
          }
          if (result.data.code === 2000) {
            // gotoLogin();
          }
          return {
            errorCode: result.data.code,
            errorRaw: result.data,
          };
        }
        return {
          data: result.data.data,
        };
      } catch (err) {
        if (err.response && err.response.status === 404) {
          if (autoToast) {
            toast.error("接口不存在");
          }
          return {
            error: err.response.data.data,
            errorRaw: {
              ...err.response.data,
              msg: "接口不存在",
            },
          };
        } else if (err.response && err.response.data.msg) {
          console.log(err.response);
          if (autoToast) {
            toast.error(err.response.data.msg);
          }
          return {
            error: err.response.data.data,
            errorRaw: {
              ...err.response.data,
            },
          };
        } else {
          console.log(err.response);
          toast.error(err.response ? "未知错误" : "网络错误");
          return {
            error: {},
            errorRaw: {
              code: -1,
              msg: err.response ? "未知错误" : "网络错误",
            },
          };
        }
      }
    };

    /**
     * 成功后redirect的方式发起POST请求
     */
    Vue.prototype.postRedirect = function (url, data) {
      const token = sessionStorage[process.env.VUE_APP_TOKEN];
      axios
        .post(API_ROOT + url, data, {
          headers: {
            Authorization: token,
          },
        })
        .then((result) => {
          if (result.data.code !== 1) {
            toast.error(result.data.msg);
            return;
          }
          window.location.href = result.data.data;
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            toast.error("接口不存在");
          } else {
            console.log(err.response);
            toast.error(err.response ? "未知错误" : "网络错误");
          }
        });
    };

    /**
     * 以 await/async 方式发起GET请求
     * @param url {string}
     * @param data {Object}
     * @param autoToast {boolean} 是否在错误时自动toast，默认true
     * @return {Result}
     */
    Vue.prototype.getAsync = async function (url, data, autoToast = true) {
      try {
        const result = await axios.get(API_ROOT + url, {
          params: data,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        });

        if (result.data.code !== 200) {
          if (autoToast) {
            toast.error(result.data.msg);
          }
          if (result.data.code === 2000) {
            // gotoLogin();
          }
          return {
            errorCode: result.data.code,
            errorRaw: result.data,
          };
        }
        return {
          data: result.data.data,
        };
      } catch (err) {
        if (err.response && err.response.status === 404) {
          if (autoToast) {
            toast.error("接口不存在");
          }
          return {
            error: err.response.data.data,
            errorRaw: {
              ...err.response.data,
              msg: "接口不存在",
            },
          };
        } else {
          console.log(err.response);
          toast.error(err.response ? "未知错误" : "网络错误");
          return {
            error: {},
            errorRaw: {
              code: -1,
              msg: err.response ? "未知错误" : "网络错误",
            },
          };
        }
      }
    };
  },
};
