import storage from "@/util/storage";
const { setItem, getItem } = storage;

// 创建store实例
export default {
  // 定义初始状态
  state: {
    // 购物车中的商品列表
    cartItems: getItem("cartItems") || [],
    minimumOrder: 20,
    freeDelivery: 60,
  },

  // 定义getters
  getters: {
    // 获取购物车中的商品总数
    cartCount: (state) => {
      return state.cartItems.reduce((total, item) => total + item.quantity, 0);
    },
    // 获取购物车中的商品总价
    cartTotal: (state) => {
      return state.cartItems
        .reduce((total, item) => total + item.productPrice * item.quantity, 0)
        .toFixed(2);
    },
    cartItems: (state) => state.cartItems,
    minimumOrder: (state) => state.minimumOrder,
    freeDelivery: (state) => state.freeDelivery,
    aboveMinimum: (state) => {
      return (
        state.cartItems
          .reduce((total, item) => total + item.productPrice * item.quantity, 0)
          .toFixed(2) >=
        state.minimumOrder * 1
      );
    },
    canFreeDelivery: (state) => {
      return (
        state.cartItems
          .reduce((total, item) => total + item.productPrice * item.quantity, 0)
          .toFixed(2) >= state.freeDelivery
      );
    },
  },

  // 定义mutations
  mutations: {
    // 增加指定商品到购物车
    addProductToCart(state, product) {
      // 查找购物车中是否已经有该商品
      const cartItem = state.cartItems.find((item) => item.id === product.id);
      if (cartItem) {
        // 如果有，增加数量
        cartItem.quantity++;
      } else {
        // 如果没有，添加新的商品
        state.cartItems.push({
          ...product,
          quantity: 1,
        });
      }
      setItem("cartItems", state.cartItems);
    },
    // 减少指定商品的数量
    removeProductFromCart(state, product) {
      // 查找购物车中是否有该商品
      const cartItem = state.cartItems.find((item) => item.id === product.id);
      if (cartItem) {
        // 如果有，减少数量
        cartItem.quantity--;
        // 如果数量为0，移除该商品
        if (cartItem.quantity === 0) {
          const index = state.cartItems.indexOf(cartItem);
          state.cartItems.splice(index, 1);
        }
      }
      setItem("cartItems", state.cartItems);
    },
    // 对指定商品设定数量
    setProductQuantity(state, payload) {
      // 查找购物车中是否有该商品
      const cartItem = state.cartItems.find(
        (item) => item.id === payload.product.id
      );
      if (cartItem) {
        // 如果有，设定数量
        cartItem.quantity = payload.quantity;
        // 如果数量为0，移除该商品
        if (cartItem.quantity === 0) {
          const index = state.cartItems.indexOf(cartItem);
          state.cartItems.splice(index, 1);
        }
      }
      setItem("cartItems", state.cartItems);
    },
    // 清空购物车
    clearCart(state) {
      state.cartItems = [];
      setItem("cartItems", state.cartItems);
    },
    setMinimumOrder(state, minimumOrder) {
      state.minimumOrder = minimumOrder;
    },
    setFreeDelivery(state, freeDelivery) {
      state.freeDelivery = freeDelivery;
    },
  },

  // 定义actions
  actions: {
    // 增加指定商品到购物车
    addProductToCart({ commit }, product) {
      commit("addProductToCart", product);
    },
    // 减少指定商品的数量
    removeProductFromCart({ commit }, product) {
      commit("removeProductFromCart", product);
    },
    // 对指定商品设定数量
    setProductQuantity({ commit }, payload) {
      commit("setProductQuantity", payload);
    },
    // 清空购物车
    clearCart({ commit }) {
      commit("clearCart");
    },
  },
};
