<template>
  <v-app>
    <v-app-bar app color="secondary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          contain
          src="./assets/logo-small.jpg"
          transition="scale-transition"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <!-- i18n toggle -->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="locale in locales" :key="locale.value">
            <v-list-item-title @click="setLocale(locale.value)">
              {{ locale.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      class="secondary text--primary"
    >
      <v-list>
        <v-list-item
          v-for="([icon, text, path], i) in items"
          :key="i"
          link
          :to="path"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main id="main-content">
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
    <v-footer v-show="!footerShow" padless class="secondary">
      <v-card flat tile width="100%" class="text-center secondary">
        <v-divider></v-divider>
        <v-card-text class="primary--text pb-0 mb-0">
          <v-btn :href="facebook" class="mx-4" icon>
            <v-icon size="24px"> mdi-facebook </v-icon>
          </v-btn>
          <v-btn :href="instagram" class="mx-4" icon>
            <v-icon size="24px"> mdi-instagram </v-icon>
          </v-btn>
          <v-btn :href="twitter" class="mx-4" icon>
            <v-icon size="24px"> $twitterx </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="primary--text">
          <v-row class="mb-2" no-gutters>
            <v-col cols="12">
              <v-btn
                @click="open('privacy')"
                class="mx-1 text-body-2 text-decoration-underline"
                text
                x-small
                >{{ $t("common.privacy") }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="open('terms')"
                class="mx-1 text-body-2 text-decoration-underline"
                text
                x-small
                >{{ $t("common.terms") }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="open('shippingPolicy')"
                class="mx-1 text-body-2 text-decoration-underline"
                text
                x-small
                >{{ $t("common.shippingPolicy") }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                @click="open('returnPolicy')"
                class="mx-1 text-body-2 text-decoration-underline"
                text
                x-small
                >{{ $t("common.returnPolicy") }}</v-btn
              >
            </v-col>
          </v-row>
          Copyright ©{{ new Date().getFullYear() }} — <strong>Re_Bagel</strong>®
        </v-card-text>
      </v-card>
      <!-- eslint-disable-next-line vue/no-parsing-error, prettier/prettier -->
    </v-footer>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
    >
      <v-card height="100vh" style="overflow: hidden">
        <v-toolbar dark color="primary">
          <v-toolbar-title> </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div style="width: 100%" class="d-flex">
          <v-progress-circular
            class="mx-auto my-10"
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            v-if="loading"
          ></v-progress-circular>
        </div>
        <iframe
          v-show="!loading"
          :src="src"
          width="100%"
          height="100%"
          frameborder="0"
          @load="loading = false"
        ></iframe>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    this._injectToToast(this.$dialog.message);
    this.footerNotShow = ["cart-view", "checkout-view"];
    this.srcs = {
      privacy:
        "https://app.enzuzo.com/policies/privacy/873875d2-d744-11ee-a135-2772b065598d",
      terms:
        "https://app.enzuzo.com/policies/tos/873875d2-d744-11ee-a135-2772b065598d",
      shippingPolicy:
        "https://app.enzuzo.com/policies/shipping/873875d2-d744-11ee-a135-2772b065598d",
      returnPolicy:
        "https://app.enzuzo.com/policies/returns/873875d2-d744-11ee-a135-2772b065598d",
    };
  },
  data() {
    return {
      drawer: false,
      locales: [
        { text: "English", value: "en" },
        { text: "中文", value: "zh" },
      ],
      items: [
        // ["mdi-home", "navList.home", "/"],
        ["mdi-food", "navList.menu", "/"],
        // ["mdi-food", "navList.menu", "/product-menu"],
        ["mdi-cart", "navList.cart", "/cart"],
        ["mdi-magnify", "navList.order", "/order"],
        ["mdi-phone", "navList.contact", "/contact"],
      ],
      twitter: "",
      facebook: "",
      instagram: "",
      src: "",
      dialog: false,
      loading: false,
    };
  },

  computed: {
    footerShow() {
      return this.footerNotShow.includes(this.$route.name);
    },
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
    async loadProdType() {
      const { data } = await this.getAsync("/dictionary/type", {
        dictType: "productType",
      });
      this.$store.commit("setType", data);
    },
    async getMinimumOrder() {
      const { errorRaw, data } = await this.getAsync("/dictionary/type", {
        dictType: "MIN_ORDER",
      });
      if (!errorRaw) {
        this.$store.commit("setMinimumOrder", data[0].dictValue);
      }
    },
    async getFreeDelivery() {
      const { errorRaw, data } = await this.getAsync("/dictionary/type", {
        dictType: "FREE_DELIVERY",
      });
      if (!errorRaw) {
        this.$store.commit("setFreeDelivery", data[0].dictValue);
      }
    },
    async getUrl() {
      const { data: twitter } = await this.getAsync("/dictionary/type", {
        dictType: "TWITTER",
      });
      const { data: facebook } = await this.getAsync("/dictionary/type", {
        dictType: "FACEBOOK",
      });
      const { data: instagram } = await this.getAsync("/dictionary/type", {
        dictType: "INSTAGRAM",
      });
      this.twitter = twitter?.[0]?.dictValue;
      this.facebook = facebook?.[0]?.dictValue;
      this.instagram = instagram?.[0]?.dictValue;
    },
    async getNTK() {
      const { data } = await this.getAsync("/dictionary/type", {
        dictType: "NTK",
      });
      this.$store.commit("setNTK", data);
    },
    open(name) {
      this.loading = true;
      this.src = this.srcs[name];
      document.body.style.overflow = "hidden";
      this.dialog = true;
    },
    close() {
      document.body.style.overflow = "auto";
      this.dialog = false;
    },
  },

  mounted() {
    this.loadProdType();
    this.getMinimumOrder();
    this.getFreeDelivery();
    this.getUrl();
    this.getNTK();
  },
};
</script>

<style scoped>
#main-content {
  background-color: #ffe3e2;
}
</style>

<style>
.v-dialog .v-toolbar--dense {
  display: none;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0 !important;
}
</style>
