import Vue from "vue";
import Vuetify from "vuetify/lib";
import twitterxIcon from "../components/twitterx-icon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        secondary: "#ffe3e2",
        primary: "#4e4443",
        error: "#E53935",
      },
    },
  },
  icons: {
    values: {
      twitterx: {
        // name of our custom icon
        component: twitterxIcon, // our custom component
      },
    },
  },
});
