import storage from "@/util/storage";
const { setItem, getItem } = storage;

// 客户端存储的地址信息
export default {
  // 定义初始状态
  state: {
    // 地址列表
    addressList: getItem("addressList") || [],
    // 当前选中的地址
    currentAddress: getItem("currentAddress") || {},
  },

  // 定义getters
  getters: {
    // 获取地址列表
    addressList: (state) => state.addressList,
    // 获取当前选中的地址
    currentAddress: (state) => state.currentAddress,
    canDeliver: (state) => {
      return state.currentAddress.orderCity === "Birmingham";
    },
  },

  // 定义mutations
  mutations: {
    // 添加地址
    addAddress(state, address) {
      state.addressList.push(address);
      setItem("addressList", state.addressList);
    },
    // 删除地址
    removeAddress(state, address) {
      const index = state.addressList.indexOf(address);
      state.addressList.splice(index, 1);
      setItem("addressList", state.addressList);
    },
    // 设置当前选中的地址
    setCurrentAddress(state, address) {
      state.currentAddress = address;
      setItem("currentAddress", state.currentAddress);
    },
    // 根据Id修改地址
    updateAddressById(state, address) {
      const index = state.addressList.findIndex(
        (item) => item.aid === address.aid
      );
      state.addressList.splice(index, 1, address);
      setItem("addressList", state.addressList);
    },
  },
};
