export default {
  state: {
    productTypes: [],
    NTK: [],
  },
  getters: {
    types: (state) => state.productTypes.map((i) => i.dictValue),
    title: (state) => state.NTK.find((i) => i.dictLabel === "title")?.dictValue,
    content: (state) =>
      state.NTK.find((i) => i.dictLabel === "content")?.dictValue,
  },
  mutations: {
    setType(state, productTypes) {
      state.productTypes = productTypes;
    },
    setNTK(state, data) {
      state.NTK = data;
    },
  },
};
