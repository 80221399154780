// localstorage封装，包含设置、获取、删除
export default {
  setItem(key, value) {
    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    window.localStorage.setItem(key, value);
  },
  getItem(key) {
    const value = window.localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
};
