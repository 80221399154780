import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VCalendar from "v-calendar";
import i18n from "./locales";
import Net from "./util/net";
import VuetifyProDialog from "vuetify-pro-dialog";
import directives from "./directives";

const actions = {
  confirm: {
    false: {
      text: () => i18n.t("common.cancel"),
      color: "error",
    },
    true: {
      text: () => i18n.t("common.ok"),
      color: "secondary",
    },
    warn: {
      text: () => i18n.t("common.warn"),
    },
    width: 450,
  },
};

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(Net);
Vue.use(directives);
Vue.use(VuetifyProDialog, { vuetify, ...actions });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
