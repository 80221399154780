/*
 * @Descripttion: 将./modules/* 目录下的指令，按照文件名称，注册到Vue中
 */
const files = require.context("./modules", false, /\.js$/);
const directives = {};
files.keys().forEach((key) => {
  directives[key.replace(/(\.\/|\.js)/g, "")] = files(key).default;
});
export default {
  install(Vue) {
    for (let key in directives) {
      Vue.directive(key, directives[key]);
    }
  },
};
